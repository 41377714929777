import { TComponent } from "@/store/builder-pro/types";
import { EComponents } from "../../components";
import {EComponentTypes} from "../../types";
import { COMMON_PROPS, TEXT_PROPS } from "../../props";

export const FRIENDLY_DESCRIPTION: TComponent = {
  componentKey: EComponents.FRIENDLY_DESCRIPTION,
  name: EComponents.FRIENDLY_DESCRIPTION,
  type: EComponentTypes.FEATURE,
  displayName: 'Description',
  noHover: true,
  noDynamic: true,
  noParent: true,
  props: {
    content: {
      name: "Text",
      defaultValue: "Example text",
    },
    textAlign: TEXT_PROPS.textAlign,
    fontSize: {
      ...TEXT_PROPS.fontSize,
      defaultValue: '4',
      items: ['1', '2', '3', '4', '5', '6', '7']
    },
    fontFamily: TEXT_PROPS.fontFamily,
    fontWeight: TEXT_PROPS.fontWeight,
    fontStyle: TEXT_PROPS.fontStyle,
    backgroundColor: COMMON_PROPS.backgroundColor,
    color: TEXT_PROPS.color,
    direction: TEXT_PROPS.direction,
  }
};
