import { TComponent } from '@/store/builder-pro/types';
import { COMMON_PROPS } from '../../props';
import { EComponents } from '../../components';
import { EComponentTypes } from "../../types";

export const FRIENDLY_RUTUBE: TComponent = {
  componentKey: EComponents.FRIENDLY_RUTUBE,
  name: EComponents.FRIENDLY_RUTUBE,
  displayName: "Rutube",
  type: EComponentTypes.FEATURE,
  props: {
    rutubeId: {
      name: 'Rutube Link / ID',
      defaultValue: 'ea59ea2cf77e83b503ef4bd8a75cc997',
    },
    backgroundColor: COMMON_PROPS.backgroundColor,
    borderRadius: COMMON_PROPS.borderRadius,
  },
};
