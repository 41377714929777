import { TComponent } from '@/store/builder-pro/types';
import { COMMON_PROPS } from '../props';
import { EComponents } from '../components';
import { EComponentTypes } from "../types";

export const RUTUBE: TComponent = {
  componentKey: EComponents.RUTUBE,
  name: EComponents.RUTUBE,
  type: EComponentTypes.COMPONENT,
  props: {
    rutubeId: {
      name: 'Rutube Link / ID',
      defaultValue: 'ea59ea2cf77e83b503ef4bd8a75cc997',
    },
    ...COMMON_PROPS,
  },
};
