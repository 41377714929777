export const CSS_PROPS: Record<string, boolean> = {
  width: true,
  cursor: true,
  minWidth: true,
  maxWidth: true,
  height: true,
  minHeight: true,
  maxHeight: true,
  margin: true,
  padding: true,
  justifyContent: true,
  alignItems: true,
  flexDirection: true,
  flexWrap: true,
  textAlign: true,
  color: true,
  fontSize: true,
  fontFamily: true,
  fontWeight: true,
  fontStyle: true,
  direction: true,
  lineHeight: true,
  background: true,
  backgroundColor: true,
  backgroundImage: true,
  backgroundPosition: true,
  backgroundSize: true,
  backgroundRepeat: true,
  border: true,
  borderLeft: true,
  borderTop: true,
  borderRight: true,
  borderBottom: true,
  borderRadius: true,
  borderCollapse: true,
  objectFit: true,
  position: true,
  top: true,
  left: true,
  right: true,
  bottom: true,
  zIndex: true,
  transition: true,
};
